:root {
  /* 153, 163, 155) */
  --main-text-color: #99a39b;
  /* var(--main-text-color) */
}

@font-face {
  font-family: 'proxima_novaregular';
  src: url('./assets/fonts/proximanova-regular-webfont.woff2') format('woff2'), url('./assets/fonts/proximanova-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_novabold';
  src: url('./assets/fonts/proximanova-bold-webfont.woff2') format('woff2'), url('./assets/fonts/proximanova-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html, div#root, div.App {
  height: 100%;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-family: 'proxima_novaregular';
  background: #99a39bff;
}

input {
  font-family: 'proxima_novaregular';
}

/* MuiVirtualizedTable-tableRow-182 MuiVirtualizedTable-flexContainer-180 */

/* .ReactVirtualized__Table__headerRow {
  padding-right: 0px !important;
} */

.ReactVirtualized__Table__headerColumn {
  line-height: 1;
  font-size: 10px !important;
}
