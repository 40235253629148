.App {
  display: flex;
  color: var(--main-text-color);
}

.App-Paper {
  /* height: 100%; */
  width: 100%;
  margin: 24px;
  margin-top: 32px;
  padding: 24px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
}

.footer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 80px;
  margin: 8px;
  justify-content: flex-end;
}

.footer p {
  margin: 2px;
  margin-left: 24px;
  font-weight: bold;
  font-size: 12px;
  flex-grow: 1;
}

.Security-div {
  display: flex;
  flex-direction: column;
  height: 100%;

  width: 100%;
}

.BrandConfiguration {
  min-height: 70%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.BrandConfigurationList{
  min-height: 70%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.BillingSetup {
  min-height: 70%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.LinkedPropertiesBrands {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.LinkedPropertiesBrands .topData {
  display: flex;
  flex-direction: row;
  /* width: 100%; */
}

.topData p {
  margin: 4px;
}

.LinkedPropertiesBrands .topData-left {
  /* display: flex; */
  /* flex-direction: row; */
  /* width: 100%; */
  margin-right: 24px;
}

.Exceptions {
  min-height: 70%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.devDiv {
  /* width: 150px; */
  position: absolute;
  display: flex;
  /* width: 100%; */
  height: 32px;
  top: 0;
  margin-left: 24px;
  align-items: flex-end;

  color: rgb(231, 231, 231);
}

.devDiv p {
  margin: 4px;
}

.userDiv{
  position: absolute;
  right: 24px;
  top: 32px;
  display: flex;
  align-items: flex-end;
  height: 32px;
  top: 0px;
  color: white;
}

.userDiv p {
  margin: 2px;
}

.filtersDiv {
  display: flex;
  /* justify-content: space-between; */
  justify-content: flex-start;
  /* align-items: flex-end; */
  margin: 8px 0;
  height: 60px;
}

.filtersDiv p {
  margin: 2px;
  padding: 0;
}

.filterItem {
  margin-right: 8px;
}

.filterItem_search_btn{
  text-align: right;
  margin-left: 48px;
}
